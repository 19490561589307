import React, { useEffect } from "react";
import {
  Descriptions,
  Breadcrumb,
  PageHeader,
  Tag,
  Carousel,
} from "antd";
import { useState } from "react";
import { connect } from "react-redux";
import { Title, Wrapper } from "./detailStyle";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player'
import { getAdById, getTvAdds } from "../../../actions/tvadd";
import './style.css';

const AdDetails = ({ location, getAdById, getTvAdds  }) => {
const [data, setData] = useState([])
const [data2, setData2] = useState([])
const [flag, setFlag] = useState(0)

useEffect( async () => {
  const id = new URLSearchParams(location.search).get("id");
  const res = await getAdById(id)
  const res2 = await getTvAdds(id)
  setData2(res2);
  setData(res[0])
}, [])

  const makeLocalTime = (date) => {
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      date.split("T")[0] +
      "," +
      hours +
      ":" +
      minutes +
      " " +
      (am ? "A.M" : "P.M")
    );
  };
  const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };
  const onChange = () => {
    setFlag( flag + 1)
    if(data2?.length - 1 === flag) setFlag(0);
  };
  const handlenextVideo = (double, multi, name, key) => {
    console.log(key)
  return(
      <ReactPlayer onEnded={onChange} url={`https://api.amz.hidayahsmart.solutions/static/${double ? 'double' : multi ? 'multi' : 'single'}/video/${name}`} 
        playing={true}
        muted={true}
      />
  ) 
  }
  return (
    <Content style={{ margin: "0 16px" }}>
       <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>TV Ad Management</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Ad Details"
        subTitle="TV Ad Management"
      >
      </PageHeader>

      <Wrapper>
        {data?.data?.status ? (
          <Tag color="#6fcf20">Active</Tag>
        ) : (
          <Tag color="#e96710">Inactive</Tag>
        )}

        <Tag color="#cd2df5">
          {data?.data?.create_at ? data?.data?.create_at : null}
        </Tag>
        <br />
        <br />
        {data ? (
          <>
            <Title>General Information</Title>
            <Descriptions>
              <Descriptions.Item label="ID">
                <strong>{data?.data?.id}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="File Type">
                <strong>{data?.data?.types}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Serial">
                <strong>{data?.data?.serial}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Duration">
                <strong>{data?.data?.duration}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Is Default">
                <strong>{data?.data?.default ? 'Yes' : 'No'}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Overlapping Ad">
                <strong>{data?.data?.double_status ? 'Yes' : 'No'}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Multi Function">
                <strong>{data?.data?.multi_slide_status ? 'Yes' : 'No'}</strong>
              </Descriptions.Item>
              <Descriptions.Item label="Start Date">
                <strong>{data?.data?.start_at}</strong>
              </Descriptions.Item> 
              <Descriptions.Item label="End Date">
                <strong>{data?.data?.end_at}</strong>
              </Descriptions.Item>
              
              {data?.data?.types === 'banner' ?
              <>
                <Descriptions.Item label="Image">
                  <img className='imgStyle' style={{ width: '100px' }} src={`https://api.amz.hidayahsmart.solutions/static/${data?.data?.uuid}_PIC.png`} alt="" />
                </Descriptions.Item>
                <Descriptions.Item label="Video">
                  <video width="320" height="240" controls>
                      <source src={`https://api.amz.hidayahsmart.solutions/static/${data?.data?.uuid}_CON.mp4`}
                      type="video/mp4" />
                  </video>
                </Descriptions.Item>
              </>
              : null}
              {data?.data?.types === 'embedvideo' ?
                <Descriptions.Item label="Thumbnail">
                  <img className='imgStyle' style={{ width: '100px' }} src={`https://api.amz.hidayahsmart.solutions/static/${data?.data?.uuid}_THUM.png`} alt="" />
                </Descriptions.Item>
              : null}
              {data?.data?.types === 'video' ?
              <>
                <Descriptions.Item label="Thumbnail">
                  <img className='imgStyle' style={{ width: '100px' }} src={`https://api.amz.hidayahsmart.solutions/static/${data?.data?.uuid}_THUM.png`} alt="" />
                </Descriptions.Item>
                <Descriptions.Item label="Video">
                  <video width="320" height="240" controls>
                      <source src={`https://api.amz.hidayahsmart.solutions/static/${data?.data?.uuid}_VID.mp4`}
                      type="video/mp4" />
                  </video>
                </Descriptions.Item>
              </>
              : null}
            </Descriptions>

            {data?.data?.types === 'embedvideo' ?
              <>
                <Title>Video Link</Title>
                <Descriptions>
                  <Descriptions.Item label="URL">
                    <strong>{data?.data?.name}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="URL">
                  <iframe 
                    width="560" 
                    height="315" 
                    src={data?.data?.name} 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen />
                  {/* <iframe width="420" height="345" src={data?.data?.name}>
                  </iframe> */}
                  </Descriptions.Item>
                  
                </Descriptions>
              </>
            : null}
            {data?.data?.double_status ?
              <>
                <Title>Overlapping Ad</Title>
                <Descriptions>
                  <Descriptions.Item label="Status">
                    <strong>{data?.double_add?.status ? 'Yes' : 'No'}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Type">
                    <strong>{data?.double_add?.types}</strong>
                  </Descriptions.Item>
                </Descriptions>
              </>
            : null}
            {data?.data?.multi_slide_status ?
              <>
                <Title>Multi Function</Title>
                <Descriptions>
                  <Descriptions.Item label="Side">
                    <strong>{data?.multi_add?.side}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Position">
                    <strong>{data?.multi_add?.topbottom}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="Type">
                    <strong>{data?.multi_add?.types}</strong>
                  </Descriptions.Item>
                  <Descriptions.Item label="status">
                    <strong>{data?.multi_add?.status ? 'Yes' : 'No'}</strong>
                  </Descriptions.Item>
                </Descriptions>
              </>
            : null}
            
          </>
        ) : null}
        <br />
        <br />
      </Wrapper>

      
      {/* {data2[flag]?.data?.convert_status === true ?
        <ReactPlayer width="100%" onError={onChange} onEnded={onChange} url={`https://api.amz.hidayahsmart.solutions/static/${data2[flag]?.data?.double_status ? 'double' : data2[flag]?.data?.multi_slide_status ? 'multi' : 'single'}/imageTovideo/${data2[flag]?.data?.uuid}.mp4`} 
          playing={true}
          muted={true}
        />
    
      :
      <ReactPlayer width="100%" onError={onChange} onEnded={onChange} url={`https://api.amz.hidayahsmart.solutions/static/${data2[flag]?.data?.double_status ? 'double' : data2[flag]?.data?.multi_slide_status ? 'multi' : 'single'}/video/${data2[flag]?.data?.name}`} 
            playing={true}
            muted={true}
          />
      } */}

    </Content>
  );
};

export default connect(null, { getAdById, getTvAdds })(
  AdDetails
);
